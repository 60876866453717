<template>
  <v-container fluid class="product-description" v-if="tabs.length > 0">
    <!-- v-tabs per visibilità desktop -->
    <template v-if="!$vuetify.breakpoint.smAndDown">
      <v-container>
        <v-tabs v-model="selectedTab" centered>
          <v-tab v-for="(tab, idx) in tabs" :key="idx" :class="tab.cssClass">
            <v-flex :class="idx + 1 < tabs.length ? 'divider' : ''"
              ><span class="px-5">{{ tab.title }}</span></v-flex
            >
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item
            v-for="(tab, idx) in tabs"
            :key="idx"
            :class="tab.cssClass"
          >
            <div
              v-for="(item, idx2) in tab.items"
              :key="idx2"
              class="pb-4"
              :class="item.cssClass"
            >
              <h4 class="text-uppercase" v-if="item.title">{{ item.title }}</h4>
              <div class="content" v-for="path in item.paths" :key="path.key">
                <component
                  :is="path.template || 'DefaultTpl'"
                  :content="path.content"
                  :append="path.append"
                />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </template>

    <template v-else>
      <v-expansion-panels
        class="mb-2"
        accordion
        flat
        tile
        :focusable="selectedTab"
      >
        <v-expansion-panel
          v-for="(tab, idx) in tabs"
          :key="idx"
          :class="tab.cssClass"
        >
          <v-expansion-panel-header class="pa-0">
            <h3 v-html="tab.title"></h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-2">
            <div
              v-for="(item, idx2) in tab.items"
              :key="idx2"
              class="pb-4"
              :class="item.cssClass"
            >
              <h4 v-if="item.title">{{ item.title }}</h4>
              <div v-for="path in item.paths" :key="path.key">
                <component
                  :is="path.template || 'DefaultTpl'"
                  :content="path.content"
                  :append="path.append"
                />
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>

<script>
import {
  DefaultTpl,
  AnchorTpl,
  LabelsTpl,
  EurospinProducerInfoTpl,
  MultipleAllergensMapTpl,
  ItemsMapTpl,
  TableTpl,
  NameAndValueTpl,
  ValueTpl,
  WarningsTpl,
  ListTpl
} from "./templates";
import { getItemsMapText } from "./templates/templateFunctions";
import forEach from "lodash/forEach";
import get from "lodash/get";
import isArray from "lodash/isArray";
import kebabCase from "lodash/kebabCase";
import tabsConfig from "/public/product/descriptionTabsConfig.json";

export default {
  name: "ProductDescription",
  /* eslint-disable vue/no-unused-components */
  components: {
    DefaultTpl,
    AnchorTpl,
    MultipleAllergensMapTpl,
    EurospinProducerInfoTpl,
    LabelsTpl,
    ItemsMapTpl,
    TableTpl,
    NameAndValueTpl,
    ValueTpl,
    WarningsTpl,
    ListTpl
  },
  data() {
    return {
      selectedTab: null,
      productDescriptionTabs: tabsConfig,
      hasWarnings: false,
      showMetrologicE: false,
      showMetrologicEDrained: false,
      showMetrologicEPackaging: false,
      showMetrologicEDrainedPackaging: false,
      showMetrologicEIsPackage: false,
      hideIngredients: false,
      showAllNutritional: true,
      allergensAdditionalText: false,
      waterTemplate: false,
      parsingErrorString:
        '<span class="error--text font-weight-bold">Errore nella lettura del dato</span>'
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productMetadata: {
      type: Object
    },
    ingredientList: {
      type: Object
    }
  },
  computed: {
    tabs() {
      let tabs = [];
      this.productDescriptionTabs.forEach(tabMeta => {
        let items = [];
        tabMeta.items.forEach(parMeta => {
          let paths = [];
          parMeta.paths.forEach(pathMeta => {
            let content;
            if (pathMeta.template === "EurospinProducerInfoTpl") {
              // questo caso si verifica per gestire il concatenamento di più metadati,
              // nel template speciale EurospinProducerInfoTpl
              content = this.parseContentForProducerInfo(pathMeta);
            } else {
              content = get(this.product, pathMeta.data || pathMeta);
            }
            let append = pathMeta.append;
            // verifico se content esiste
            if (content) {
              //se è stringa e diverso da un tag "p" modello il content in base ai template
              if (typeof content === "string" && content !== "<p></p>") {
                if (pathMeta.template === "TableTpl") {
                  // se è un template TableTpl verifico se le sue celle sono vuote
                  try {
                    let cells = JSON.parse(content).cells;
                    let hasContent = cells.find(c => {
                      return c !== null;
                    });
                    if (!hasContent) {
                      return;
                    }
                  } catch (e) {
                    console.error(
                      `Error parsing metaData --> KEY: ${pathMeta.data}, VALUE: ${content}`
                    );
                    content = this.parsingErrorString;
                  }
                }
                if (pathMeta.template === "ValueTpl") {
                  let value;
                  try {
                    value = JSON.parse(content).value;

                    if (!value) {
                      return;
                    }
                  } catch (e) {
                    console.error(
                      `Error parsing metaData --> KEY: ${pathMeta.data}, VALUE: ${content}`
                    );
                    value = this.parsingErrorString;
                  }
                }

                if (pathMeta.template === "WarningsTpl") {
                  // se il template è WarningsTpl verifico che almeno uno dei suoi valori sia popolato
                  let values;
                  try {
                    values = JSON.parse(content)[0]["items-map"];
                    let keyList =
                      global.config.productDescriptionWarningsKeyList;
                    this.hasWarnings = false;
                    forEach(keyList, k => {
                      if (
                        values[k].value !== null &&
                        values[k].value !== undefined
                      ) {
                        this.hasWarnings = true;
                        return false;
                      }
                    });
                    if (!this.hasWarnings) {
                      return;
                    }
                  } catch (e) {
                    console.error(
                      `Error parsing metaData --> KEY: ${pathMeta.data}, VALUE: ${content}`
                    );
                    values = [this.parsingErrorString];
                  }
                }
              }
              paths.push({
                key: pathMeta.data?.toString() || pathMeta?.toString(),
                content: content,
                template: pathMeta.template,
                append: append
              });
            }
          });
          if (paths.length > 0) {
            items.push({
              title: parMeta.title,
              paths: paths,
              cssClass: get(parMeta, "cssClass", kebabCase(parMeta.title))
            });
          }
        });
        if (items.length > 0) {
          tabs.push({
            title: tabMeta.title,
            items: items,
            cssClass: get(tabMeta, "cssClass", kebabCase(tabMeta.title))
          });
        }
      });

      // testo aggiuntivo in fondo al tab Ingredienti
      let ingredientsTab = tabs.find(t => {
        return t.title === "Ingredienti";
      });
      if (ingredientsTab) {
        var _this = this;
        _this.foundAllergens = false;
        _this.multiAllergens = false;
        _this.multiAllergens = false;
        var staticDisclaimer = {
          title: "",
          paths: [
            {
              key: "metaData.product_description.STATIC_DISCLAIMER",
              content: this.$t("productDescription.staticDisclaimer")
            }
          ]
        };
        ingredientsTab.items.push(staticDisclaimer);
        ingredientsTab.items.find(ing => {
          if (ing.title == "Allergeni:") {
            ing.paths.filter(function(el) {
              if (
                el.key === "metaData.product_description.ALLERGENS_MULT" ||
                el.key === "metaData.product_description.ALLERGENS" ||
                el.key === "metaData.product_description.ALLERGENS_ELEMENTS"
              ) {
                if (el.key === "metaData.product_description.ALLERGENS_MULT") {
                  JSON.parse(el.content).find(o => {
                    if (
                      !o["items-map"].allergens.options ||
                      (o["items-map"].allergens.options.length == 0 &&
                        !_this.foundAllergens)
                    ) {
                      _this.hideAllergens = true;
                      _this.multiAllergens = false;
                    } else {
                      _this.multiAllergens = true;
                      _this.foundAllergens = true;
                      _this.hideAllergens = false;
                    }
                  });
                } else if (
                  el.key === "metaData.product_description.ALLERGENS" &&
                  !_this.foundAllergens
                ) {
                  _this.foundAllergens = true;
                  _this.foundOriginalAllergens = true;
                  _this.hideAllergens = false;
                } else {
                  if (
                    el.key === "metaData.product_description.ALLERGENS_ELEMENTS"
                  ) {
                    _this.foundAllergens = true;
                    _this.foundAllergensElements = true;
                    _this.hideAllergens = false;

                    var ingredientsFound = [];

                    var elements = JSON.parse(el.content);

                    for (var i = 0; i < elements.length; i++) {
                      elements[i]["items-map"].allergens.options.forEach(al => {
                        ingredientsFound.push(al);
                      });
                    }

                    const key = "label";
                    ingredientsFound = [
                      ...new Map(
                        ingredientsFound.map(item => [item[key], item])
                      ).values()
                    ];
                    if (ingredientsFound.length > 0)
                      el.content = JSON.stringify(ingredientsFound);
                  }
                }
              }
            });
          }

          if (ing.title == "Url etichetta elettronica") {
            ing.paths.filter(function(el) {
              if (
                el.key === "metaData.product_description.DIGITAL_LABEL" &&
                el.content
              ) {
                console.log("content", el.content);
              }
            });
          }
          if (ing.title == "Ingredienti:") {
            ing.paths.filter(function(el) {
              if (el.key === "metaData.product_description.INGREDIENTS_HTML") {
                if (el.content.length == 0 || el.content == "<p></p>") {
                  _this.hideIngredients = true;
                } else {
                  _this.hideIngredients = false;
                }
              }
            });
          }
        });

        ingredientsTab.items.find(x => {
          if (x.allergensAdditionalText) {
            this.allergensAdditionalText = true;
          }
          if (this.hideAllergens) {
            x.paths = x.paths.filter(function(el) {
              return (
                el.key !== "metaData.product_description.ALLERGENS_MULT" &&
                el.key !== "metaData.product_description.ALLERGENS" &&
                el.key != "metadata.product_description.ALLERGENS_ELEMENTS"
              );
            });
          } else {
            if (
              _this.multiAllergens &&
              !_this.foundOriginalAllergens &&
              !_this.foundAllergensElements
            ) {
              x.paths = x.paths.filter(function(el) {
                return (
                  el.key !== "metaData.product_description.ALLERGENS" &&
                  el.key !== "metaData.product_description.ALLERGENS_ELEMENTS"
                );
              });
            } else if (
              _this.foundOriginalAllergens &&
              !_this.multiAllergens &&
              !_this.foundAllergensElements
            ) {
              x.paths = x.paths.filter(function(el) {
                return (
                  el.key !== "metaData.product_description.ALLERGENS_MULT" &&
                  el.key !== "metaData.product_description.ALLERGENS_ELEMENTS"
                );
              });
            } else if (_this.foundAllergensElements) {
              x.paths = x.paths.filter(function(el) {
                return (
                  el.key !== "metaData.product_description.ALLERGENS_MULT" &&
                  el.key !== "metaData.product_description.ALLERGENS"
                );
              });
            } else {
              return true;
            }
          }
        });

        ingredientsTab.items = ingredientsTab.items.filter(el => {
          if (this.hideIngredients) {
            return el.title !== "Ingredienti:";
          } else {
            return true;
          }
        });

        ingredientsTab.items = ingredientsTab.items.filter(el => {
          if (this.hideAllergens) {
            return el.title !== "Allergeni:";
          } else {
            return true;
          }
        });

        if (!this.hideIngredients && this.allergensAdditionalText) {
          ingredientsTab.items.push({
            paths: [
              {
                content: this.$t("productDescription.ingredientsAdditionalInfo")
              }
            ]
          });
        }
        if (ingredientsTab.items.length === 0) {
          tabs = tabs.filter(el => {
            return el.title !== "Ingredienti";
          });
        }
      }
      let descriptionTab = tabs.find(t => {
        return t.title === "Descrizione";
      });
      if (descriptionTab) {
        const estimatedSymbol =
          '<span class="ml-1" style="color: #333333;font-size: 20px;">℮</span>';

        descriptionTab.items.find(d => {
          if (d.title === "Quantità:") {
            var custom_paths = [];
            // If METROLOGIC_E_DESCRIPTION exists, return it, else do the evaluation for metrologic_e and NET_WEIGHT
            const metrologicEDescription = d.paths.find(
              e =>
                e.key ===
                "metaData.product_description.METROLOGIC_E_DESCRIPTION"
            );
            if (metrologicEDescription) {
              d.paths = [metrologicEDescription];
              return;
            }
            d.paths.find(e => {
              if (
                e.key === "metaData.product_description.METROLOGIC_E" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicE = true;
                return;
              }
            });
            d.paths.find(e => {
              if (
                e.key ===
                  "metaData.product_description..METROLOGIC_E_IS_PACKAGE" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicEIsPackage = true;
                return;
              }
            });
            d.paths.find(e => {
              if (
                e.key ===
                  "metaData.product_description.METROLOGIC_E_IS_PACKAGE" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicEIsPackage = true;
                return;
              }
            });

            d.paths.find(e => {
              if (
                e.key ===
                  "metaData.product_description.METROLOGIC_E_PACKAGING" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicEPackaging = true;
                return;
              }
            });

            d.paths = d.paths.filter(function(el) {
              return el.key !== "metaData.product_description.METROLOGIC_E";
            });

            d.paths = d.paths.filter(function(el) {
              return (
                el.key !== "metaData.product_description.METROLOGIC_E_PACKAGING"
              );
            });

            d.paths.find(p => {
              if (p.key === "metaData.product_description.AVARAGE_WEIGHT") {
                custom_paths.push(p);
                d.paths = custom_paths;
                return;
              }
            });
            if (
              this.showMetrologicEPackaging ||
              (this.showMetrologicE && this.showMetrologicEIsPackage)
            ) {
              d.paths.find(p => {
                const index = p.content.lastIndexOf(")");
                if (index !== -1) {
                  p.content =
                    p.content.slice(0, index) +
                    estimatedSymbol +
                    p.content.slice(index);
                }
                console.log("BBB", p.content);
              });
            }

            // if (this.showMetrologicEPackaging) {
            //   d.paths.find(p => {
            //     const index = p.content.lastIndexOf(")");
            //     if (index !== -1) {
            //       p.content =
            //         p.content.slice(0, index) +
            //         estimatedSymbol +
            //         p.content.slice(index);
            //     }
            //     console.log("BBB", p.content);
            //   });
            // }
            else if (this.showMetrologicE) {
              d.paths.find(p => {
                p.content = `${p.content}${estimatedSymbol}`;
              });
            }
          }
          if (d.title === "Peso sgocciolato:") {
            var custom_paths_drained = [];
            d.paths.find(e => {
              if (
                e.key === "metaData.product_description.METROLOGIC_E_DRAINED" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicEDrained = true;
                return;
              }
            });
            d.paths.find(e => {
              if (
                e.key ===
                  "metaData.product_description.METROLOGIC_E_IS_PACKAGE" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicEIsPackage = true;
                return;
              }
            });
            d.paths.find(e => {
              if (
                e.key ===
                  "metaData.product_description.METROLOGIC_E_DRAINED_PACKAGING" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicEDrainedPackaging = true;
                return;
              }
            });

            d.paths = d.paths.filter(function(el) {
              return (
                el.key !== "metaData.product_description.METROLOGIC_E_DRAINED"
              );
            });

            d.paths = d.paths.filter(function(el) {
              return (
                el.key !==
                "metaData.product_description.METROLOGIC_E_DRAINED_PACKAGING"
              );
            });

            d.paths.find(p => {
              if (p.key === "metaData.product_description.NET_DRAINED_WEIGHT") {
                custom_paths_drained.push(p);
                d.paths = custom_paths_drained;
                return;
              }
            });
            if (
              this.showMetrologicEDrainedPackaging ||
              (this.showMetrologicEDrained && this.showMetrologicEIsPackage)
            ) {
              d.paths.find(p => {
                const index = p.content.lastIndexOf(")");

                if (index !== -1) {
                  p.content =
                    p.content.slice(0, index) +
                    estimatedSymbol +
                    p.content.slice(index);
                }
              });
            }
            // if (this.showMetrologicEDrainedPackaging) {
            //   d.paths.find(p => {
            //     const index = p.content.lastIndexOf(")");

            //     if (index !== -1) {
            //       p.content =
            //         p.content.slice(0, index) +
            //         estimatedSymbol +
            //         p.content.slice(index);
            //     }
            //   });
            // }
            else if (
              this.showMetrologicEDrained &&
              !this.showMetrologicEDrainedPackaging
            ) {
              // console.log("i should not enter");
              d.paths.find(p => {
                p.content = p.content + estimatedSymbol;
              });
            }
          }
          if (d.title === "Importato da:") {
            d.paths.find(p => {
              if (p.key === "metaData.product_description.IMPORTED_ADDRESS") {
                JSON.parse(p.content).find(o => {
                  if (
                    (o["items-map"].businessName.value &&
                      o["items-map"].businessName.value != "") ||
                    (o["items-map"].address.value &&
                      o["items-map"].address.value != "")
                  ) {
                    p.content =
                      (o["items-map"].businessName.value
                        ? o["items-map"].businessName.value
                        : "") +
                      (o["items-map"].address.value
                        ? o["items-map"].address.value
                        : "");
                  } else {
                    d.paths = d.paths.filter(function(el) {
                      return (
                        el.key !==
                        "metaData.product_description.IMPORTED_ADDRESS"
                      );
                    });
                  }
                });
              }
            });
          }
          if (d.title === "Numero identificativo:") {
            var custom_content = "";
            d.paths.find(p => {
              if (
                p.key === "metaData.product_description.IDENTIFICATION_NUMBER"
              ) {
                var arr = [];
                if (p.content.search("items-map") > -1) {
                  arr = JSON.parse(p.content);
                }
                if (isArray(arr)) {
                  arr.find(o => {
                    custom_content =
                      custom_content + o["items-map"].value.value + ", ";
                  });
                  custom_content = custom_content.substring(
                    0,
                    custom_content.length - 2
                  );
                  p.content = custom_content;
                }
              }
            });
          }
        });
      }
      let componentsTab = tabs.find(t => {
        return t.title === "Componenti";
      });
      if (componentsTab) {
        componentsTab.items.find(d => {
          if (d.title === "Per alimenti") {
            var cp = [];
            d.paths.find(p => {
              if (p.key === "metaData.product_description.FOR_FOOD") {
                if (p.content === "Sì")
                  p.content = "Per contatto con prodotti alimentari";
                cp.push(p);
                d.paths = cp;
                return;
              }
            });
          }
        });
      }
      var nutritionalValueTabs = tabs.find(n => {
        return n.title === "Valori nutrizionali";
      });
      if (nutritionalValueTabs) {
        if (!_this.waterTemplate) {
          nutritionalValueTabs.items.find(function(item) {
            item.paths = item.paths.filter(function(el) {
              return el.key !== "metaData.product_description.DISSOLVED_TEXT";
            });
          });
          nutritionalValueTabs.items = nutritionalValueTabs.items.filter(
            function(item) {
              return item.title !== "Analisi chimico fisiche:";
            }
          );
        }
        var showNutritional = [];
        let analyticalResult = [];
        nutritionalValueTabs.items.find(v => {
          v.paths.find(function(el) {
            if (
              (el.template && el.template === "TableTpl") ||
              el.key == "metaData.product_description.ANALYTICAL_COMPONENTS"
            ) {
              showNutritional.push(el);
            }
            if (
              el.key == "metaData.product_description.ANALYTICAL_COMPONENTS"
            ) {
              JSON.parse(el.content).find(o => {
                if (
                  (o["items-map"].analyticalComponent.options &&
                    o["items-map"].analyticalComponent.options.length > 0) ||
                  (o["items-map"].otherComponents &&
                    o["items-map"].otherComponents.value) ||
                  (o["items-map"].value && o["items-map"].value.value) ||
                  (o["items-map"].percentage && o["items-map"].percentage.value)
                ) {
                  var analytical =
                    (o["items-map"].analyticalComponent &&
                    o["items-map"].analyticalComponent.options[0] &&
                    o["items-map"].analyticalComponent.options[0].label
                      ? o["items-map"].analyticalComponent.options[0].label
                      : "") +
                    " " +
                    (o["items-map"].otherComponents &&
                    o["items-map"].otherComponents.value
                      ? o["items-map"].otherComponents.value
                      : "") +
                    " " +
                    (o["items-map"].value && o["items-map"].value.value
                      ? o["items-map"].value.value
                      : "") +
                    " " +
                    (o["items-map"].percentage &&
                    o["items-map"].percentage.value
                      ? o["items-map"].percentage.value
                      : "");
                  analyticalResult.push(analytical);
                }
              });
              el.content = "";
              for (var a = 0; a < analyticalResult.length > 0; a++) {
                el.content += analyticalResult[a] + ", ";
              }
              el.content = el.content.substring(0, el.content.length - 2);
            }
          });
        });
        if (showNutritional.length == 0) {
          tabs = tabs.filter(el => {
            return el.title !== "Valori nutrizionali";
          });
        }
      }
      return tabs;
    }
  },
  methods: {
    parseContentForProducerInfo(pathMeta) {
      let tempContent = {};
      let stringType = 1;
      forEach(pathMeta.data, v => {
        let dataValue = get(this.product, v, null);
        if (dataValue) {
          try {
            if (dataValue.search("items-map") > -1) {
              // dataValue = dataValue.replace(/<\/?p[^>]*>/g, "");
              let parsed = JSON.parse(dataValue);
              if (parsed) {
                if (parsed.elements && parsed.elements.length > 0) {
                  dataValue = isArray(parsed)
                    ? parsed[0]["elements"]
                    : parsed.elements;
                } else {
                  // se uno dei metadati è la stringa di un JSON di tipo "items-map"
                  // provvedo a manipolare l'oggetto per ottenere una stringa utile
                  dataValue = getItemsMapText(
                    isArray(parsed)
                      ? parsed[0]["items-map"]
                      : parsed["items-map"],
                    false
                  );
                }
              }
            }
          } catch (e) {
            console.error(
              `Error parsing metaData --> KEY: ${v}, VALUE: ${dataValue}`
            );
            dataValue = this.parsingErrorString;
          }

          let key = this._generateKey(v);
          tempContent[key] = dataValue;
          if (key === "SUPPLIER_BRAND" || key === "BRAND_EUROSPIN") {
            stringType = 2;
          }
          if (key === "MULTI_FACTORIES") {
            stringType = 3;
          }
          if (key === "BOTTLED" || key === "BOTTLED_BY") {
            this.waterTemplate = true;
          }
        }
      });

      // gestione di eventuali valori undefined
      let prodAndPackaging = tempContent.PROD_AND_PACKAGING || "";
      let eurospinText = tempContent.EUROSPIN_TEXT || "";
      let eurospinTextEus = tempContent.EUROSPIN_TEXT_EUS || "";
      let factoryProductionPackaging =
        tempContent.FACTORY_PRODUCTION_PACKAGING || "";
      let factories = tempContent.FACTORIES || "";
      let factoriesEus = tempContent.FACTORIES_EUS || "";
      let supplierBrand = tempContent.SUPPLIER_BRAND || "";
      let brandEus = tempContent.BRAND_EUROSPIN || "";
      let bottled = tempContent.BOTTLED || "";
      let bottledBy = tempContent.BOTTLED_BY || "";
      let multiFactories = tempContent.MULTI_FACTORIES || "";

      // gestione della casistica in cui nessuno dei valori sia popolato,
      // quindi nessuna info sul produttore
      if (
        !prodAndPackaging &&
        !eurospinText &&
        !eurospinTextEus &&
        !factoryProductionPackaging &&
        !supplierBrand &&
        !brandEus &&
        !bottled &&
        !bottledBy &&
        !multiFactories &&
        !factories &&
        !factoriesEus
      ) {
        stringType = 0;
      }
      // gestione delle tre casistiche
      switch (stringType) {
        default:
        case 0:
          return "";
        case 1:
          if (factories && factories != "" && !bottled && !bottledBy) {
            return `${prodAndPackaging} ${eurospinText} da: <br/>${
              factories[0] &&
              factories[0]["items-map"] &&
              factories[0]["items-map"].factory &&
              factories[0]["items-map"].factory.value
                ? factories[0]["items-map"].factory.value
                : ""
            } <br/> ${
              factories[1] &&
              factories[1]["items-map"] &&
              factories[1]["items-map"].factory &&
              factories[1]["items-map"].factory.value
                ? factories[1]["items-map"].factory.value
                : ""
            } <br/> ${multiFactories}`;
          } else if (factoriesEus && factoriesEus != "") {
            return `${prodAndPackaging} ${eurospinTextEus} da: <br/>${
              factoriesEus[0] &&
              factoriesEus[0]["items-map"] &&
              factoriesEus[0]["items-map"].factory &&
              factoriesEus[0]["items-map"].factory.value
                ? factoriesEus[0]["items-map"].factory.value
                : ""
            } <br/> ${
              factoriesEus[1] &&
              factoriesEus[1]["items-map"] &&
              factoriesEus[1]["items-map"].factory &&
              factoriesEus[1]["items-map"].factory.value
                ? factoriesEus[1]["items-map"].factory.value
                : ""
            } <br/> ${multiFactories}`;
          } else if (bottled && bottled != "" && bottledBy && bottledBy != "") {
            return ` Imbottigliata ${bottled} nello stabilimento di: ${bottledBy}`;
          } else {
            return `${prodAndPackaging} ${eurospinText} da: <br/>${factoryProductionPackaging} ${multiFactories}`;
          }
        case 2:
          if (brandEus && brandEus != "") {
            return `${
              prodAndPackaging ? prodAndPackaging + "da" : ""
            } ${brandEus}`;
          } else {
            return `${
              prodAndPackaging ? prodAndPackaging + "da" : ""
            } ${supplierBrand}`;
          }

        case 3:
          if (factories && factories != "") {
            let result = "";
            if (
              factories[0] &&
              factories[0]["items-map"] &&
              factories[0]["items-map"].factory &&
              factories[0]["items-map"].factory.value
            ) {
              result = `${prodAndPackaging} ${multiFactories} ${eurospinText} ${
                multiFactories.indexOf(":") > -1 ? "" : "da"
              } <br/>${
                factories[0]["items-map"].factory.value
                  ? factories[0]["items-map"].factory.value
                  : ""
              }`;
            }
            if (
              factories[1] &&
              factories[1]["items-map"] &&
              factories[1]["items-map"].factory &&
              factories[1]["items-map"].factory.value
            ) {
              result += `<br/> ${
                factories[1]["items-map"].factory.value
                  ? factories[1]["items-map"].factory.value
                  : ""
              }`;
            }
            return result;
          } else if (factoriesEus && factoriesEus != "") {
            let res = `${prodAndPackaging} ${multiFactories} ${eurospinTextEus} ${
              multiFactories.indexOf(":") > -1 ? "" : "da"
            }`;
            if (
              factoriesEus[0] &&
              factoriesEus[0]["items-map"] &&
              factoriesEus[0]["items-map"].factory &&
              factoriesEus[0]["items-map"].factory.value
            ) {
              res += `<br/> ${
                factoriesEus[0]["items-map"].factory.value
                  ? factoriesEus[0]["items-map"].factory.value
                  : ""
              }`;
            }
            if (
              factoriesEus[1] &&
              factoriesEus[1]["items-map"] &&
              factoriesEus[1]["items-map"].factory &&
              factoriesEus[1]["items-map"].factory.value
            ) {
              res += `<br/> ${
                factoriesEus[1]["items-map"].factory.value
                  ? factoriesEus[1]["items-map"].factory.value
                  : ""
              }`;
              return res;
            } else {
              return `${prodAndPackaging} ${eurospinText} da: <br/>${factoryProductionPackaging} ${multiFactories}`;
            }
          }
      }
    },
    _generateKey(v) {
      let array = v.split(".");
      return array.slice(-1).pop();
    }
  }
};
</script>
